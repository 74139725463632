import { Inject, Injectable, computed, inject, signal } from '@angular/core'
import { orderBy, where } from '@angular/fire/firestore'
import { 
  CheaseedEnvironment, 
  GROUPS_COLLECTION, 
  MAX_GROUP_NAME_LENGTH, 
  MIN_GROUP_NAME_LENGTH, 
  splitTrim2,
  AFFILIATE_TERMS,
  Group, 
  GroupConverter, 
  GroupMember, 
  GroupMemberConverter,
  LedgerEntry,
  GroupLedgerEntry,
  GROUP_LEDGER_COLLECTION_NAME,
  GROUP_BALANCE_LOW,
  GROUP_MEMBERS_COLLECTION_NAME,
  USERS_COLLECTION
} from '@cheaseed/node-utils'
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilKeyChanged, filter, firstValueFrom, map, of, shareReplay, switchMap, tap } from 'rxjs'

import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router } from '@angular/router'
import { FirebaseService } from './firebase.service';
import { ContentService } from './content.service';
import { CheaseedUser, SharedUserService } from './shared-user.service';
import { Events, SharedEventService } from './shared-event.service'
import { connect } from 'ngxtension/connect';

export interface GroupsState {
  // Current user group
  currentUserGroup: Group | null
  // Group mgmt state
  selectedGroupDocId: string | null
  selectedGroup: Group | null
  selectedGroupMembers: GroupMember[]
  selectedGroupLedgerEntries: GroupLedgerEntry[]
}

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  private router = inject(Router)
  firebase = inject(FirebaseService)
  contentService = inject(ContentService)
  private userService = inject(SharedUserService)
  private eventService = inject(SharedEventService)

  // Use signals for state

  checkingGroupToJoin = signal<Group|null>(null)

  private state = signal<GroupsState>({
    currentUserGroup: null,
    selectedGroupDocId: null,
    selectedGroup: null,
    selectedGroupMembers: [],
    selectedGroupLedgerEntries: []
  })

  selectedGroupDocId = computed(() => this.state().selectedGroupDocId)
  selectedGroup = computed(() => this.state().selectedGroup)
  selectedGroupMembers = computed(() => this.state().selectedGroupMembers)
  selectedGroupLedgerEntries = computed(() => this.state().selectedGroupLedgerEntries)
  currentUserGroup = computed(() => this.state().currentUserGroup)
  currentGroupTerms = computed(() => {
    const group = this.currentUserGroup()
    const termName = (group?.approvedAt && group?.pricingPlan === 'affiliate' && group?.affiliateTerms) ? group.affiliateTerms : undefined
    return {
      pricingPlan: group?.pricingPlan,
      unlimitedPrepaid: group?.hasUnlimitedBalance && group?.pricingPlan === 'prepaid', 
      affiliateTerms: this.findAffiliateTerms(termName as string),
      pricePerCoach: group?.pricePerCoach,          
      prepaidBalance: group?.pricingPlan === 'prepaid' ? group?.balance : undefined
    }
  })

  // Use sources for events

  waitingForAuthentication$ = new BehaviorSubject<any>(null)
  selectedGroupDocId$ = new BehaviorSubject<string | null>(null)

  // User's groups
  ownedGroups$ = this.userService.userLoggedIn$
    .pipe(
      // distinctUntilKeyChanged('docId'),
      // tap((user) => console.log('ownedGroups$', user?.docId)),
      switchMap(user => user?.isAnonymousUser ? of([]) : this.getGroups(user as CheaseedUser))
    )

  selectedGroup$ = this.selectedGroupDocId$
    .pipe(
      // filter(docId => !!docId),
      switchMap(docId => docId ? this.getGroup(docId as string) : of(null)),
      shareReplay(1)
    )

  selectedGroupMembers$ = this.selectedGroup$
    .pipe(
      switchMap(group => this.getGroupMembers(group?.docId as string)),
      shareReplay(1))

  selectedGroupLedgerEntries$ = this.selectedGroup$
    .pipe(
      switchMap(group => this.getGroupLedger(group?.docId as string)),
      shareReplay(1))
  
  currentUserGroup$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      distinctUntilKeyChanged("groupDocId"),
      switchMap(user => user?.groupDocId ? this.getGroup(user.groupDocId as string) : of(null)),
      shareReplay(1)
    )

  constructor(
    @Inject('environment') public environment: CheaseedEnvironment,
    @Inject('UtilityService') public utilityService: any) { 
      combineLatest([ this.waitingForAuthentication$, this.userService.requireLogin$, this.userService.user$ ])
        .pipe(
          filter(([ waiting, requireLogin, user ]) => !!waiting && !requireLogin && !user?.isAnonymousUser),
          takeUntilDestroyed()
        )
        .subscribe(([ waiting, requireLogin, user ]) => {
          this.waitingForAuthentication$.next(null)
            this.joinGroup(user as CheaseedUser, waiting.group)
        })

        // Connect all observables to the state
        // See https://www.youtube.com/watch?v=R7-KdADEq0A
        connect(this.state)
          .with(this.selectedGroup$, (prev, selectedGroup) => ({ ...prev, selectedGroup }))
          .with(this.selectedGroupMembers$, (prev, selectedGroupMembers) => ({ ...prev, selectedGroupMembers }))
          .with(this.selectedGroupLedgerEntries$, (prev, selectedGroupLedgerEntries) => ({ ...prev, selectedGroupLedgerEntries }))
          .with(this.currentUserGroup$, (prev, currentUserGroup) => ({ ...prev, currentUserGroup }))

        // effect(() => console.log('GroupsState', this.state()))
  }

  getGroupPath(groupId: string) {
    return `${GROUPS_COLLECTION}/${groupId}`
  }

  getGroupLedgerPath(groupId: string) {
    return `${this.getGroupPath(groupId)}/${GROUP_LEDGER_COLLECTION_NAME}`
  }
  
  getGroupMemberPath(groupId: string) {
    return `${this.getGroupPath(groupId)}/${GROUP_MEMBERS_COLLECTION_NAME}`
  }

  private getGroups(user: CheaseedUser) {
    return this.firebase.collection$(GROUPS_COLLECTION, where('ownerUserId', '==', user.docId), orderBy('createdAt', 'desc'))
      .pipe(
        debounceTime(200),
        map(groups => GroupConverter.fromArray(groups)),
        tap(res => console.log(`retrieved ${res.length} groups for user ${user.docId}`)),
        // TODO: augment with member counts
        shareReplay(1)
      )
  }

  getGroup(docId: string) {
    return this.firebase.doc$(this.getGroupPath(docId))
      .pipe(
        debounceTime(300),
        map(group => GroupConverter.fromFirestoreData(group)),
        tap(res => console.log(`retrieved group`, res)),
        shareReplay(1)
      )
  }

  getGroupMembers(docId: string)  {
    return this.firebase.collection$(this.getGroupMemberPath(docId))
      .pipe(
        debounceTime(300),
        map(members => GroupMemberConverter.fromArray(members)),
        tap(res => console.log(`retrieved group members`, res)),
        shareReplay(1)
      )
  }

  getGroupLedger(docId: string)  {
    return this.firebase.collection$(this.getGroupLedgerPath(docId), orderBy('createdAt', 'desc'))
      .pipe(
        debounceTime(300),
        tap(res => console.log(`retrieved group ledger entries`, res)),
        shareReplay(1)
      )
  }

  async createGroup(groupName: string, userId: string | undefined) {
    if (await this.checkGroupName(groupName)) {
      const now = new Date()
      await this.putGroup({} as Group, { 
          name: groupName, 
          ownerUserId: userId,
          createdAt: now,
          updatedAt: now
      })
      this.eventService.record(Events.GroupCreated, { name: groupName, groupOwner: userId })
    }
    else {
      await this.utilityService.presentToast(`Invalid group name: ${groupName}`)
    }
  }

  // Return true if group name is new for user
  private async checkGroupName(name: string) {
    if(!name || name.length < MIN_GROUP_NAME_LENGTH || name.length > MAX_GROUP_NAME_LENGTH) {
      console.error(`Group name must be between ${MIN_GROUP_NAME_LENGTH} and ${MAX_GROUP_NAME_LENGTH} characters`)
      return false
    }
    const groups = await firstValueFrom(this.ownedGroups$)
    return !groups?.find(g => g.name === name)
  }

  async putGroup(group: Group, params: any) {
    console.log('putGroup', group, params)
    const now = new Date()
    // create or update
    if (!group.docId) {
      try {
        group.docId = this.firebase.generateDocID()
      }
      catch (e) {
        console.error('Failed to generate docId in firebase, using timestamp', e)
        // Compute number of milliseconds since epoch
        group.docId = `${now.getTime()}`
      }
    }
    const path = `${GROUPS_COLLECTION}/${group.docId}`
    // if there is no invitation link, generate one
    const data = !(group.invitationLink || params.invitationLink)
      ? { ...params, docId: group.docId, invitationLink: await this.generateBranchInviteLink({ ...group, ...params}) }
      : { ...params, docId: group.docId }
    await this.firebase.updateAt(path, { ...data, updatedAt: now }) 
    this.eventService.record(Events.GroupUpdated, { 
      groupName: group.name, 
      groupOwner: group.ownerUserId, 
      pricingPlan: group.pricingPlan, 
      invitationLink: group.invitationLink,
      balance: group.balance,
      hasUnlimitedBalance: group.hasUnlimitedBalance,
      affiliateTerms: group.affiliateTerms,
      pricePerCoach: group.pricePerCoach
    })
  }

  async generateBranchInviteLink(group: Group) {
    const analytics = {
      channel: 'app',
      feature: 'invite-to-group',
      campaign: group.name,
    }

    let og_image
    try {
      const globals = await firstValueFrom(this.contentService.globals$)
      og_image = globals.get("webchat.social.imageurl")
    }
    catch(e) {
      console.error('Failed to get globals', e)
    }
    og_image = og_image || "https://cheaseed.github.io/cheaseed/public/socialShareImage1080x1080.png"
    const og_title = `Join "${group.name}" to access chea seed for career advice`
    const og_description = "Click to join chea seed!"
    const url = `${this.environment.portalHost}/group-invite/${group.docId}`

    // optional fields
    const properties = {
      web_only: true,
      $web_only: true,
      $desktop_web_only: true,
      $mobile_web_only: true,
      $ios_url: url,
      $android_url: url,
      $desktop_url: url,
      $ipad_url: url, 
      $og_title: og_title,
      $og_description: og_description,
      $og_image_url: og_image,
      $twitter_card: 'summary_large_image',
      $twitter_title: og_title,
      $twitter_description: og_description,
      $twitter_image_url: og_image,
      $twitter_site: '@cheaseed'
    }

    const linkData = {
      ...analytics,
      data: { ...properties }
    }
    
    // See index.html for branch initialization
    let link = undefined
    try {
      link = await this.createBranchLink(linkData)
    }
    catch (e) {
      console.error('Failed to generate branch link', e)
    }
    // console.log('generated link', link, properties)
    return link
  }

  createBranchLink(...args) {
    return new Promise((resolve, reject) => {
      window['branch'].link(...args, (err:any, data:any) => {
        if (err) return reject(err)
        resolve(data)
      })
    })
  }

  sendInvitationEmail(group: Group, email: string) {
    this.firebase.callCloudFunction("sendEmailAttachment",
    {
      to: email,
      subject: 'Join chea seed group: ' + group.name,
      text: 'Click this link to join the group: ' + group.invitationLink
    }).subscribe(result => console.log(result))
  }  

  sendPendingApprovalEmail(group: Group, pendingEmail: string) {
    this.firebase.callCloudFunction("sendEmailAttachment",
    {
      to: group.ownerUserId,
      subject: `${pendingEmail} is waiting for approval to join group ${group.name}`,
      text: `\nYou are an owner of ${group.name}. Click this link to manage approvals: ${this.environment.portalHost}/group/${group.docId}?tab=members`
    }).subscribe(result => console.log(result))
  }  

  async checkJoinGroup(user: CheaseedUser, group: Group) {
    console.log('checkJoinGroup', user, group)
    this.checkingGroupToJoin.set(group)
    if (user?.isAnonymousUser) {
      this.waitingForAuthentication$.next({ group })
      this.userService.requestLogin$.next(true)
    }
    else
      await this.joinGroup(user, group)
  }

  async joinGroup(user: CheaseedUser, group: Group) {
    if (user.groupDocId === group.docId) {
      await this.utilityService.presentToast(
        `You are already a member of group ${group.name}`,
        { duration: 4000 })
      this.routeHome()
    }
    else if (user.groupDocId && user.groupDocId !== group.docId) { // Handle switching groups
      const currentGroup = await firstValueFrom(this.getGroup(user.groupDocId as string))
      if (currentGroup) {
        let msg = this.contentService.getGlobal('portal.group.leave.message') ||
          `You are currently a member of group $0. Are you sure you want to withdraw from that group and join $1 ?`
        msg = msg.replace(/\$\w+/, currentGroup?.name)
        msg = msg.replace(/\$\w+/, group.name)
        await this.utilityService.confirm({
          header: 'Join Group',
          message: msg,
          confirm: async () => {
            await this.deleteMember(user.docId as string, currentGroup)
            await this.addUserToNewGroup(user, group)
            this.routeHome()
          },
          cancel: () => { this.routeHome() }  
          })
      }
      else {
        await this.addUserToNewGroup(user, group)
        this.routeHome()  
      }
    }
    else {
      await this.addUserToNewGroup(user, group)
      this.routeHome()
    }
    this.checkingGroupToJoin.set(null)
  }

  routeHome() {
    this.router.navigateByUrl('/home')
  }

  async addUserToNewGroup(user: CheaseedUser, group: Group) {
    const members = await firstValueFrom(this.getGroupMembers(group.docId as string))
    const member = members.find(m => m.userId.toLowerCase() === user.docId?.toLowerCase())
    const isPreApproved = member?.status === 'pre-approved'
    const isInvited = member?.status === 'invited'
    console.log('joinGroup', { user, group, isPreApproved })
    // Pending now only applies to prepaid groups 05/30/2024
    let status = 'active'
    if (isPreApproved || isInvited || group.pricingPlan === 'affiliate') {
      const message = `You are now a member of the group ${group.name}.`
      await this.userService.setGroupDocId(user.docId as string, group.docId as string)
      await this.putGroupMember(group, { userId: user.docId as string, status } as GroupMember)
      await this.utilityService.presentToast(message, { duration: 4000 })
    }
    else {
      status = 'pending'
      const message = `The administrator of group ${group.name} needs to approve your membership.`
      await this.putGroupMember(group, { userId: user.docId as string, status } as GroupMember)
      await this.utilityService.presentToast(message, { duration: 4000 })
      this.sendPendingApprovalEmail(group, user.docId as string)
    }
    this.eventService.record(Events.GroupJoined, { name: group.name, groupOwner: group.ownerUserId, pricingPlan: group.pricingPlan, status })
  }

  async addPreApprovedMembers(group: Group, emailStr: string, members: GroupMember[]) {
    const emails = splitTrim2(emailStr)
    const newEmails = emails.filter(e => !members.find(m => m.userId === e)).map(e => e.toLowerCase())
    for (const e of newEmails) {
      this.putGroupMember(group, { userId: e, status: 'pre-approved' } as GroupMember)
    }
  }

  async inviteMembers(group: Group, emailStr: string, members: GroupMember[]) {
    const emails = splitTrim2(emailStr)
    const newEmails = emails.filter(e => !members.find(m => m.userId === e)).map(e => e.toLowerCase())
    const msgs = []
    const link = `${group.invitationLink}?ct=true`
    for (const email of newEmails) {
      this.putGroupMember(group, { userId: email, status: 'invited' } as GroupMember)
      msgs.push({
        user: email,
        eventName: Events.GroupSendInvitation,
        attributes: { 
          group: group.name, 
          groupOwner: group.ownerUserId,
          pricingPlan: group.pricingPlan,
          email, 
          link,
          description: group.description
        }
      })
      // TODO: eventually remove this
      // this.sendInvitationEmail(group, email)
    }
    // Make one call to sendCleverTapEvent with a list of events and setProfile: true, max 1000 invitees
    try {
      await this.firebase.awaitCloudFunction('sendCleverTapEvent', {
        setProfiles: true,
        events: msgs      
      })
    }
    catch (e) {
      console.error('Failed in sendCleverTapEvent', e)
    }
    this.eventService.record(Events.GroupInvitationSent, { name: group.name, invitees: newEmails, link: group.invitationLink })
  }

  async putGroupMember(group: Group, member: GroupMember) {
    await this.firebase.updateAt(`${this.getGroupMemberPath(group.docId as string)}/${member.userId}`, { ...member, updatedAt: new Date() })
  }
  
  findAffiliateTerms(name: string) {
    return AFFILIATE_TERMS.find(t => t.name === name)
  }

  async approveGroupMember(userId: string, group: Group) {
    console.log('approveMember', userId, group)
    const now = new Date()
    await this.putGroupMember(group, { userId, status: 'active', approvedAt: now } as GroupMember)
    await this.userService.setGroupDocId(userId, group.docId as string)
    await this.firebase.awaitCloudFunction('sendCleverTapEvent', { 
      user: userId,
      eventName: Events.GroupMemberApproved,
      attributes: { 
        group: group.name, 
        userId, 
        groupOwner: group.ownerUserId,
        pricingPlan: group.pricingPlan,
        description: group.description }
    })
  }

  canRemoveGroupMember(member: GroupMember) {
    return member.status !== 'active'
  }

  async removeGroupMember(member: GroupMember, group: Group) {
    await this.utilityService.confirm({
      header: 'Remove Member',
      message: `Are you sure you want to remove ${member.userId} from the group?`,
      confirm: () => {
        this.leaveGroup(member.userId, group)
      }
    })
  }

  async removeAllGroupMembers(group: Group, members: GroupMember[]) {
    for (const m of members)
      await this.leaveGroup(m.userId, group)
  }

  async removeGroupUser(userId: string, group: Group) {
    await this.utilityService.confirm({
      header: 'Leave Group',
      message: `Are you sure you want to leave ${group.name}?`,
      confirm: () => {
        this.leaveGroup(userId, group)
      }
    })
  }

  // Remove the member from the group and update user
  async leaveGroup(userId: string, group: Group) {
    console.log('leaveGroup', userId, group)
    // Make sure user exists
    const user = await firstValueFrom(this.firebase.doc$(this.userService.getUserPath(userId)))
    if (user?.docId) {
      await this.userService.setGroupDocId(userId, null)
    }
    await this.deleteMember(userId, group)
  }

  async deleteMember(userId: string, group: Group) {
    await this.firebase.delete(`${this.getGroupMemberPath(group.docId as string)}/${userId}`)
  }

  async handleGroupPayment(user: CheaseedUser, seedType: string) {
    const group = this.currentUserGroup() as Group
    // if prepaid group and balance is sufficient or unlimited
    if (this.isPrepaidWithBalance(group)) {
      const newBalance = Number((group.balance - group.pricePerCoach).toFixed(2))
      if (!group.hasUnlimitedBalance) {       
        await this.putGroup(group, { balance: newBalance })
        if (newBalance <= GROUP_BALANCE_LOW) {
          console.log(`Low Balance detected ${newBalance} - sending event to clevertap`)
          this.eventService.record(Events.GroupBalanceLow,
            {
              name: group.name,
              groupOwner: group.ownerUserId,
              balance: newBalance
            })
        }
      }
      const summary = this.contentService.getSingularGlobal(seedType)
      // add a ledger entry in the group and the user indicating credit use
      const ledgerEntry: LedgerEntry = {
        createdAt: new Date(),
        userId: user?.docId as string,
        type: 'debit',
        source: 'prepaid',
        origin: group.docId,
        amount: group.pricePerCoach || 0,
        description: `Purchased ${summary} via prepaid group ${group.hasUnlimitedBalance ? 'with unlimited balance.' : '' }`
      }
      await this.userService.addUserLedgerEntry(ledgerEntry)
      const groupLedgerEntry: GroupLedgerEntry = {
        ...ledgerEntry,
        balance: group.hasUnlimitedBalance ? undefined : newBalance,
        source: 'purchase' //TODO - should this be something else ?
      }
      await this.addGroupLedgerEntry(groupLedgerEntry, group)
      await this.utilityService.presentToast(
        this.contentService.getGlobal('portal.prepaid.chatPurchased.message') || 'Your group purchased this chat for you!',
        { duration: 6000 })
      return false
    }
    return true
  }

  async addGroupLedgerEntry(entry: GroupLedgerEntry, group: Group) {
    const path = this.getGroupLedgerPath(group.docId as string)
    return await this.firebase.updateAt(path, entry)
  }

  isPrepaidWithBalance(group: Group | null) {
    return group?.pricingPlan === 'prepaid' && ((group.balance || 0) > (group.pricePerCoach || 0) || group.hasUnlimitedBalance)
  }

  async updateGroupStats(groupId: string) {
    await this.firebase.awaitCloudFunction('computeGroupSummary', { groupId })
  }

  async obliterateGroup(group: Group) {
    const groupDocId = group.docId as string
    const ledgers = await firstValueFrom(this.getGroupLedger(groupDocId))
    const members = await firstValueFrom(this.getGroupMembers(groupDocId))
    await Promise.all(ledgers.map((l:any) => this.firebase.delete(`${this.getGroupLedgerPath(groupDocId)}/${l.docId}`)))
    await Promise.all(members.map((l:any) => this.firebase.delete(`${this.getGroupLedgerPath(groupDocId)}/${l.docId}`)))
    await this.firebase.delete(this.getGroupPath(groupDocId))
    // Identify all users in the group and remove the groupDocId
    const users = await firstValueFrom(this.firebase.collection$(`${USERS_COLLECTION}`, where('groupDocId', '==', groupDocId)))
    for (const user of users) {
      await this.userService.setGroupDocId(user.docId, null)
    }
  }
}