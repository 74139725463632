import OpenAI, { AzureOpenAI } from 'openai';
import { MAX_OPENAI_RETRIES, getAzureOpenAIAssistantId, getAzureOpenAIServer } from './constants';
import { LoggerInterface } from './logger-interface';
import { OpenAIInterface, OpenAIConfig, OpenAIResult } from './openai-interface';
import { OpenAIDriverInterface, Prompt } from './prompts-base';
import { Message } from 'openai/resources/beta/threads/messages';

export class OpenAIAssistant implements OpenAIInterface {
  
  openai: OpenAI
  beta: OpenAI.Beta
  assistant: OpenAI.Beta.Assistants.Assistant | undefined
  openaiThread: OpenAI.Beta.Threads.Thread | undefined
  environment: any;
  logger?: LoggerInterface
  driver: OpenAIDriverInterface

  constructor(config: OpenAIConfig) {
    this.environment = config.env
    const server = getAzureOpenAIServer(config.env)
    this.openai = new AzureOpenAI({
        dangerouslyAllowBrowser: !!config.isLocal,
        endpoint: server.endpoint,
        deployment: config.model,
        apiVersion: "2024-07-01-preview",
        apiKey: server.key,
        maxRetries: MAX_OPENAI_RETRIES
        // retryDelayInMs: 100,
    })
    this.beta = this.openai.beta
    this.logger = config.logger || console
    this.driver = config.driver
    this.logger.log(`Using Azure OpenAI server: ${JSON.stringify(server)}`)
  }

  async getAssistant() {
    if (!this.assistant) {
      try {
        const assistantId = getAzureOpenAIAssistantId(this.environment)
        this.assistant = await this.beta.assistants.retrieve(assistantId)
        // this.logger.log('Retrieve assistant response', this.assistant)
      }
      catch (e) {
        console.error('Error creating assistant', e)
      }
    }
    return this.assistant;
  }
  async getThread(): Promise<OpenAI.Beta.Threads.Thread> {
    if (!this.openaiThread) {
      this.openaiThread = await this.beta.threads.create()
      this.logger.log('OpenAI Assistant Thread created with id:', JSON.stringify(this.openaiThread));
    }
    return this.openaiThread
  }

  async createThread(prompt: string) {
    let fileIds: string[] = []
    let filteredPrompt = prompt
    // Extract attribute names for each file to attach
    const regex = /\<Attachment\>(.*)\<\/Attachment\>/g; // /([^%]+)%/g;
    const attachAttrs = [ ...prompt.matchAll(regex) ].map(match => match[1]);
    if (attachAttrs) {
      const urls = await Promise.all(attachAttrs.map(attr => this.driver.getAttachmentUrl(attr)))
      for (const url of urls) {
        const file = await this.openai.files.create({
          file: { url, blob: () => fetch(url).then(res => res.blob()) },
          purpose: "assistants",
        });
        fileIds.push(file.id)
      }
      filteredPrompt = prompt.replace(regex, '')
      // Delete file in storage after attaching
      // await Promise.all(attachAttrs.map(attr => this.driver.deleteAttachment(attr)))
    }
    else
        console.log('No attachments found in prompt')
    const attachments = fileIds.map(id => ({ file_id: id, tools: [{ type: "file_search" }] } as Message.Attachment))
    console.log('ATTACHMENTS:', JSON.stringify(attachments))
    const msg = { 
      role: "user", 
      content: filteredPrompt, 
      attachments: attachments.length > 0 ? attachments : undefined
    } as OpenAI.Beta.Threads.ThreadCreateParams.Message
    const thread = await this.beta.threads.create({ messages: [ msg ] });
    return { thread, fileIds }
  }

  async waitForRunCompletion(
    runId: string,
    threadId: string,
    interval: number = 1000,
    timeout: number = 540000
  ): Promise<OpenAI.Beta.Threads.Messages.MessagesPage> {
    let timeElapsed = 0;

    return new Promise(async (resolve, reject) => {
      while (timeElapsed < timeout) {
        const res = await this.beta.threads.runs.retrieve(threadId, runId)
        if (res.status === 'completed') {
          const messagesFromThread = await this.beta.threads.messages.list(threadId)
          this.logger.log(`Run completed ${interval}ms after ${timeElapsed}ms`)
          resolve(messagesFromThread)
          return
        } else if ( ['failed', 'cancelled', 'expired'].includes(res.status) ) {
          reject(new Error(`Run ended with status: ${res.status}`));
          return
        }
        this.logger.log(`Waiting ${interval}ms for run to complete...`)
        await new Promise((resolve) => setTimeout(resolve, interval))
        timeElapsed += interval
      }
      reject(
        new Error('Timeout: Run did not complete within the specified time.')
      )
    })
  }

  debug(...args: any[]) {
    this.logger.log(...args)
  }

  async logChatQuery(
    config: OpenAIConfig,
    prompt: string,
    prompts: Prompt[],
    queryOptions: any
    ) {

    const startmsecs = Date.now()
    const asst = await this.getAssistant()
    const { thread, fileIds } = await this.createThread(prompt)
    // console.log('ASSISTANT:', asst)
    // console.log('THREAD:', thread)
    // console.log('FILEIDS:', fileIds)
    let run = await this.beta.threads.runs.createAndPoll(thread.id, { assistant_id: asst.id } )
    if (run.status !== 'completed')
      throw new Error(`Run ended with status: ${run.status}`)
    console.log('RUN:', JSON.stringify(run, null, 2))

    const messages = await this.beta.threads.messages.list(thread.id, {run_id: run.id });
    const message = messages.data.pop()!;
    console.log('OUTPUT:', JSON.stringify(message, null, 2))
    if (message.content[0].type !== "text")
      throw new Error(`Message type is not text, it is: ${message.content[0].type}: ${message.content[0]}`)
    const { text } = message.content[0];
    const { annotations } = text;
    const citations: string[] = [];
    for (let annotation of annotations) {
      text.value = text.value.replace(annotation.text, "");
    }

    // const res = await this.waitForRunCompletion(run.id, run.thread_id)
    // const tm = res.messages.getPaginatedItems()
    const elapsedMsec = Date.now() - startmsecs
    const obj: OpenAIResult = {
      message: { role: 'assistant', content: text.value },
      finish_reason: run.status,
      input_usage: run.usage?.prompt_tokens || 0,
      output_usage: run.usage?.completion_tokens || 0,
      usage: run.usage?.total_tokens || 0,
      elapsedMsec,
      temperature: config.temperature,
      top_p: config.topP,
      model: run.model
    }

    // Clean up thread
    await this.beta.threads.del(thread.id)
    for (const id of fileIds)
      await this.openai.files.del(id)

    return obj
  }

}
