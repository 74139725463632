import { Injectable } from '@angular/core'
import {
  writeBatch,
  collection,
  doc,
  docData,
  DocumentReference,
  Firestore,
  query,
  collectionData,
  docSnapshots,
  collectionGroup,
  getDoc,
  auditTrail,
  addDoc,
  FieldValue,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  setDoc,
  deleteDoc,
  DocumentSnapshot,
  Timestamp,
  FirestoreDataConverter,
  updateDoc,
  increment,
  getDocs,
  getCountFromServer,
  collectionChanges,
  runTransaction,
  Transaction
} from '@angular/fire/firestore'
import { getFunctions, httpsCallable } from '@angular/fire/functions'
import { Observable, combineLatest, defer, from, map, switchMap } from 'rxjs'
import { deleteObject, getDownloadURL, ref, getStorage, uploadBytesResumable, percentage, UploadTask, getBlob } from '@angular/fire/storage'
//import { AngularFirestore } from '@angular/fire/compat/firestore'
// workaround for issue with referencing a namespace as moment - causes warnings
// in the TS compiler
import { AuthService } from './auth.service'
import { DocumentData } from '@angular/fire/firestore';

const FIREBASE_UPLOAD_PREFIX = 'files'

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  contentConfigTimestamp: any

  constructor(
    private firestore: Firestore,
    private auth: AuthService
  ) { }

  getFirestore() {
    return this.firestore
  }

  writeBatch() {
    return writeBatch(this.firestore)
  }

  takeUntilAuth<T>() {
    return this.auth.takeUntilAuth<T>()
  }

  auditTrail(path: string) {
    auditTrail(collection(this.firestore, path))
      .pipe(this.takeUntilAuth())
      .subscribe(console.log)
  }

  // Note: An orderBy() clause also filters for existence of the given fields. The result set will not include documents that do not contain the given fields.
  collection$(path: string, ...constraints): Observable<any> {
    // console.log('collection$', path, ...constraints)
    return collectionData(this.queryCollection(path, null, ...constraints), {idField: 'docId'})
      .pipe(this.takeUntilAuth())
  }

  queryCollection(path: string, converter: FirestoreDataConverter<any>| null , ...constraints) {
    return query(
      collection(this.firestore, path).withConverter(converter as FirestoreDataConverter<any>),
      ...constraints).withConverter(converter as FirestoreDataConverter<any>)
  }

  collectionChanges$(path: string, converter: FirestoreDataConverter<any>, ...constraints) {
    return collectionChanges(this.queryCollection(path, converter, ...constraints))
      .pipe(this.takeUntilAuth())
  }

  // For experimental support of paginated queries (using startAt, startAfter, limit constraints)
  async getQuerySnapshotData(path: string, position: any[], limit: any[], constraints: any[]) {
    // console.log('getQuerySnapshotData', path, ...constraints, ...position, ...limit)
    const query = this.queryCollection(path, null, ...constraints, ...position, ...limit )
    const querySnapshot = await getDocs(query)
    return querySnapshot.docs
  }

  async getCollectionCount(path: string, ...constraints) {
    const query = this.queryCollection(path, null, ...constraints)
    const snapshot = await getCountFromServer(query)
    return snapshot.data().count
  }

  collectionWithConverter$(path: string, converter: FirestoreDataConverter<any>, ...constraints): Observable<any> {
    return collectionData(query(
      collection(this.firestore, path).withConverter(converter),
      ...constraints),
      { idField: 'docId' }
    )
      .pipe(this.takeUntilAuth())
  }

  collectionGroupQuery$(path: string, ...constraints) {
    return query(
        collectionGroup(this.firestore, path),
        ...constraints
      )
  }

  collectionGroupQueryWithConverter$(path: string, converter: FirestoreDataConverter<any>, ...constraints) {
    return query(
      collectionGroup(this.firestore, path).withConverter(converter),
      ...constraints
    ).withConverter(converter)
  }

  /* Use collectionGroupDocs$ to get a list of data objects from a collection group */
  collectionGroup$(path: string, ...constraints): Observable<any> {
    return collectionData(
      this.collectionGroupQuery$(path, ...constraints),
      { idField: 'docId' })
      .pipe(this.takeUntilAuth())
  }

  collectionGroupWithConverter$(path: string, converter: FirestoreDataConverter<any>, ...constraints) {
    return collectionData(
      this.collectionGroupQueryWithConverter$(path, converter, ...constraints),
      { idField: 'docId' })
      .pipe(this.takeUntilAuth())
  }

  /* Use collectionGroupDocs$ to get a list of documents from a collection group:

    return this.firebaseService.collectionGroupDocs$('offers', where('name', '==', name))
      .pipe(
        map(coll =>
          coll.docs.map(doc => {
            if (doc.id !== name) {
              const data = doc.data()
              data.docId = doc.id
              if (!data.userId) {
                const match = doc.ref.path.match(pattern)
  */
  collectionGroupDocs$(path: string, ...constraints) {
    return from(getDocs(
      this.collectionGroupQuery$(path, ...constraints)))
  }

  doc$(path: string): Observable<any> {
    return docData(doc(this.firestore, path), { "idField": 'docId' })
      .pipe(
        this.takeUntilAuth())
  }

  /**
   * 
   * @param path 
   * Only use when the query does not involve a user id 
   */
  docWithoutAuth$(path: string) {
    return docData(doc(this.firestore, path), { "idField": 'docId' })
  }
  
  docWithConverter$(path: string, converter: FirestoreDataConverter<any>) {
    // console.log('docWithConverter$', path)
    return docData(
      doc(this.firestore, path).withConverter(converter),
      { "idField": 'docId' })
      .pipe(this.takeUntilAuth())
  }

  // See https://medium.com/@joaqcid/how-to-inner-join-data-from-multiple-collections-on-angular-firebase-bfd04f6b36b7
  // See https://fireship.io/lessons/firestore-joins-similar-to-sql/
  // Custom operator for joining
  // const user$ = afs.document('users/jeff').valueChanges()
  // const joined = user$.pipe(
  //    docJoin(afs, { car: 'cars', pet: 'pets' } )
  // )
  // result:
  // {
  //   ...userData
  //   pet: { type: 'dog', food: 'kibble' },
  //   car: { model: 'Legacy', doors: 4 }
  // }
  docJoin(paths: { [key: string]: string }) {
    return source =>
      defer(() => {
        let parent
        const keys = Object.keys(paths)

        return source.pipe(
          switchMap(data => {
            // Save the parent data state
            parent = data

            // Map each path to an Observable
            const docs$ = keys.map(k => {
              const fullPath = `${paths[k]}/${parent[k]}`
              return docData(doc(this.firestore, fullPath))
            })

            // return combineLatest, it waits for all reads to finish
            return combineLatest(docs$)
          }),
          map(arr => {
            // We now have all the associated douments
            // Reduce them to a single object based on the parent's keys
            const joins = keys.reduce((acc, cur, idx) => {
              return { ...acc, [cur]: arr[idx] }
            }, {})

            // Return the parent doc with the joined objects
            return { ...parent, ...joins }
          })
        )
      })
  }

  async updateAtAsTransaction(path: string, data: any, merge = true, converter?: FirestoreDataConverter<any> | undefined): Promise<any> {
    await runTransaction(this.firestore, async(t) => await this.updateAt(path, data, merge, converter))
  }

  async updateAt(path: string, data: any, merge = true, converter?: FirestoreDataConverter<any> | undefined): Promise<any> {
   
    const segments = path.split('/').filter(v => v)
    // console.log( 'updateAt ', path)
    if (segments.length % 2) {
      // Odd is always a collection
      console.log(`Adding ${path}`)
      const coll = converter ?
        collection(this.firestore, path).withConverter(converter):
        collection(this.firestore, path)
        
      return addDoc(coll, data)
    }
    else {
      // Even is always a document
      console.log(`Updating ${path}`)
      //console.log(`Data = ${JSON.stringify(data)}`)
      const docRef = converter ?
        doc(this.firestore, path).withConverter(converter) :
        doc(this.firestore, path)
      //console.log(`Updating ${JSON.stringify(docRef)}`)
      //console.log('DATA: ', JSON.stringify(data))
      return setDoc(
        docRef,
        data,
        { merge }
      )
    }
  }

  /**
   * Returns a FieldValue sentinel for use in a call to `set()` or `update()`.
   **/
  updateArrayUnion(item): FieldValue {
    return arrayUnion(item)
  }
  updateArrayRemove(item): FieldValue {
    return arrayRemove(item)
  }
  updateTimestamp(): FieldValue {
    return serverTimestamp()
  }
  increment(val: number): FieldValue {
    return increment(val)
  }

  timestampFromDate(d: Date) {
    return Timestamp.fromDate(d)
  }

  delete(path:string) {
    console.log(`Deleting document ${path}`)
    return deleteDoc(doc(this.firestore, path))
  }

  deleteRecursively(path:string) {
    return this.callCloudFunction('recursiveDelete', { path: path })
  }

  // Return an array of documents for ids
  // readDocs(db.collection('products), ['foo', 'bar', 'baz'])
  async readDocs(collection, ids: []) {
    const reads = ids.map(id => collection.doc(id).get())
    const result = await Promise.all(reads)
    return result.map(v => v.data())
  }

  getVideoUrl(file: string): Observable<string> {
    const storageRef = ref(getStorage(), `${FIREBASE_UPLOAD_PREFIX}/${file}`)
    return from(getDownloadURL(storageRef))
  }

  deleteVideoUrl(filename: string) {
    const storageRef = ref(getStorage(), `${FIREBASE_UPLOAD_PREFIX}/${filename}`)
    return deleteObject(storageRef)
  }

  deleteFile(filename: string) {
    const storageRef = ref(getStorage(), filename)
    return deleteObject(storageRef)
  }

  getTakeawayUrl(id: string) {
    const key = `media/cropped_tiles/${id}.png`,
      storageRef = ref(getStorage(), key)
    return from(getDownloadURL(storageRef))
  }

  async uploadFile(file: File): Promise<string> {
    const randomId = Math.random().toString(36).substring(2, 4);
    const ext = file.name.split('.').pop();
    const basename = `${Date.now()}_${randomId}.${ext}`;
    const path = `${FIREBASE_UPLOAD_PREFIX}/${basename}`;
    const storageRef = ref(getStorage(), path);
    const uploadTask = uploadBytesResumable(storageRef, file, { contentDisposition: 'attachment; filename*=utf-8\'\'' + file.name });

    await new Promise<void>((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        error => reject(error),
        () => {
          console.log("upload completed", file.name, path);
          resolve();
        }
      );
    });

    return path;
  }

  async prepareUploadFile(url: string) {
    console.log("uploadFile about to read", url)
    const response = await fetch(url)
    const blob = await response.blob()
    console.log("blob", blob.size, blob.type)
    const randomId = Math.random().toString(36).substring(2, 4);
    const ext = url.split('.').pop()
    const basename = `${Date.now()}_${randomId}.${ext}`
    const filename = `${FIREBASE_UPLOAD_PREFIX}/${basename}`
    return { blob: blob, filename: filename, basename: basename }
  }

  async uploadLocalUrl(localUrl: string) {
    const { blob, filename, basename } = await this.prepareUploadFile(localUrl)
    const storageRef = ref(getStorage(), filename)
    const uploadTask: UploadTask = uploadBytesResumable( storageRef, blob )
    let uploadProgress = 0.0
    percentage(uploadTask).subscribe(change => {
      console.log("task progress", change)
      uploadProgress = change.progress / 100.0
    });

    uploadTask.then(async () => {
      console.log("task complete")
      uploadProgress = 0.0
    })
    return basename
  }

  async getDownloadURL(file: string) {
    return await getDownloadURL(ref(getStorage(), file))
  }

  async getFileAsStream(storagePath: string) {
    console.log(`getFileAsStream: ${storagePath}`)
    const storageRef = ref(getStorage(), storagePath)
    const blob = await getBlob(storageRef)
    // convert to stream as fs.ReadStream
    return await blob.stream()
    // return blob.arrayBuffer()
    // const stream = blob.stream()
    // return stream
  }

  getFirestoreDocRef(ref: string, converter?: FirestoreDataConverter<any>): DocumentReference<DocumentData> {
    return converter ?
      doc(this.firestore, ref).withConverter(converter) :
      doc(this.firestore, ref)
  }

  async getFirestoreDoc(ref: string, converter?: FirestoreDataConverter<any>): Promise<DocumentData> {
    const doc: DocumentSnapshot<DocumentData> = await getDoc(this.getFirestoreDocRef(ref, converter))
    const data = doc.data()
    // console.log(`getFirestoreDoc: ${ref}`, data)
    return data as DocumentData
  }

  getFirestoreCollectionRef(ref: string, converter?: FirestoreDataConverter<any>) {
    return converter ?
      collection(this.firestore, ref).withConverter(converter) :
      collection(this.firestore, ref)
  }


  /**
   * Creates a new document in the collection ref with an autogenerated id and adds data to it
   * @param ref
   */
  async setData(ref: any, data: any, converter? : FirestoreDataConverter<any>) {
    const docId = this.generateDocID()
    //console.log('In setData ref = ', JSON.stringify(ref))
    let docRef: DocumentReference<DocumentData>
    if (ref.firestore) {
      docRef = converter?
        doc(ref, docId).withConverter(converter) :
        doc(ref, docId)
    }
    else {
      docRef = converter ?
        doc(this.firestore, ref, docId).withConverter(converter) :
        doc(this.firestore, ref, docId)
    }
    await setDoc(docRef, data)
    return docId
  }

  generateDocID() {
    return doc(collection(this.firestore, 'id')).id
  }

  firestoreSnapshotChanges(ref) {
    return docSnapshots(doc(ref))
  }

  async awaitCloudFunction(funcname: string, args: any, timeout?: number) {
    console.log(`Calling cloud function ${funcname}`, timeout, args)
    const callable = timeout ?
      httpsCallable(getFunctions(), funcname, { timeout }) :
      httpsCallable(getFunctions(), funcname)
    return callable(args)
  }

  callCloudFunction(funcname: string, args: any, timeout?: number): Observable<any> {
    return from(this.awaitCloudFunction(funcname, args, timeout))
  }

  incrementField(path: string, field: string, value: number) {
    const docRef = doc(this.firestore, path)
    return updateDoc(docRef, {
      [field]: this.increment(value)
    })
  }

}

